<template lang="pug">
form-layout(title="ᲠᲔᲒᲘᲡᲢᲠᲐᲪᲘᲐ")
	template(#right)
		RegistrationForm
</template>

<script>
import FormLayout from "../components/layout/FormLayout";
import RegistrationForm from "../components/forms/RegistrationForm";

export default {
	name: "Registration",
	components: {
		FormLayout,
		RegistrationForm
	}
}
</script>

<style lang="scss" scoped>

</style>
