<template lang="pug">
.form
    h1
    .inputs
            DefaultInput(type="text" v-model:value="data.first_name" :errors="errors.first_name" text="სახელი")
            DefaultInput(type="text" v-model:value="data.last_name" :errors="errors.last_name" text="გვარი")
            DefaultInput(type="email" v-model:value="data.email" :errors="errors.email" text="მეილი")
            DefaultInput(type="tel" v-model:value="data.phone_number" :errors="errors.phone_number" text="ტელეფონი")
            DefaultInput(type="password" v-model:value="data.password" :errors="errors.password" text="პაროლი")
            DefaultInput(type="password" v-model:value="data.password2" :errors="errors.password2 || errors.password" text="გაიმეორე პაროლი" @enterClick="register()")
    .center
        DefaultButton(text="ᲠᲔᲒᲘᲡᲢᲠᲐᲪᲘᲐ" @clicked="register()")
        .not-register
            span დარეგისტრირებული ხარ?
            router-link(:to="{name:'Login'}") შესვლა
</template>

<script>
import DefaultInput from "../layout/DefaultInput";
import DefaultButton from "../layout/DefaultButton";

export default {
    name: "Registration",
    components: {
        DefaultInput,
        DefaultButton
    },
    data() {
        return {
            errors: {},
            data: {
                first_name: null,
                last_name: null,
                email: null,
                phone_number: null,
                password: null,
                password2: null
            }
        }
    },
    methods: {
        register() {
            this.$store.dispatch("user/userRegister",this.data).then( res => {
                this.errors = {}
                this.$router.push({ name: 'Verification', params: {uid: res.uid, token: res.token }})
            }).catch( err => {
                this.errors = err
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.form {
    width: 100%;
    padding: 0 20px;
    color: #434242;

    & > h1 {
        font-size: 18px;
        text-align: center;
    }

    .inputs {
        width: 100%;
        max-width: 552px;
        margin: 33px auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 19px 26px;

        @media (max-width: 992px) {
            grid-template-columns: 1fr;
            max-width: 263px;
        }
    }

    .center {
        display: grid;
        justify-content: center;
        text-align: center;

        .not-register {
            font-size: 12px;
            margin-top: 11px;

            a {
                cursor: pointer;
                padding-left: 6px;
                color: black;
                font-weight: bold;
                text-decoration: none;
            }
        }
    }
}
</style>
